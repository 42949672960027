import React, { Component } from 'react'

export default class PageTitle extends Component {
  render() {
    return (
      <div className="container">
        <div className="section">
          <h2 className="title is-2 has-text-grey-dark has-text-weight-light has-text-left is-uppercase">
            {this.props.title}
          </h2>
          <h3 className="subtitle has-text-left has-text-grey">{this.props.subtitle}</h3>
        </div>
      </div>
    )
  }
}
